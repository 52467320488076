h1, .h1 {
  font-size: $h1-font-size-lg;
  font-size: calc( .038 * 100vw );
}
h2, .h2 {
  font-size: $h2-font-size-lg;
  font-size: calc( .038 * 100vw );

  .h2sup {
    font-size: $h2-font-size-lg;
    font-size: calc( .038 * 100vw );
  }
}
h3, .h3 {
  font-size: $h4-font-size-lg;
}

//
// Content
//

.entry-header {
  margin-bottom: 0;

  .col-12:not(.pfc) {
    align-self: center;
    margin-bottom: $space-8;
  }

  .breadcrumb,
  .entry-title,
  .header-meta {
    padding-left: $space-8;
    padding-right: $space-4;
  }

  &.light {
    .breadcrumb,
    .entry-title {
      margin-top: $space-8;
    }
  }

}
.breadcrumb li>* {
  font-size: $h4-font-size-lg;
}
.header-meta {
  margin-bottom: $space-8;
}

.intro {
  columns: 2;
  column-gap: $grid-gutter-width * 2;
  margin-top: $space-8;

  p {
    &:first-child {
      margin-top: 0;
    }
  }
  .vc {
    margin-left: $grid-gutter-width;
    margin-right: -$grid-gutter-width;
  }
}

.entry-content {

  p,
  cite {
    max-width: $space-14;
  }

}

.pfc {

  padding: 0;
  margin-right: -$grid-gutter-width;
  width: calc( 50% + #{math.div( $grid-gutter-width, 2 )} );
  flex-basis: calc( 50% + #{math.div( $grid-gutter-width, 2 )} );
  max-width: calc( 50% + #{math.div( $grid-gutter-width, 2 )} );

  img {
    border-radius: 1px 0 0 1px;

    @include dark() {
      box-shadow: 0 0 math.div( $grid-gutter-width, 2 ) rgba( 0, 0, 0, .24 );
    }
  }

}

.entries {
  li {
    margin-top: 0;
  }
}
