@use "sass:math";

@import 'bootstrap/functions';
@import 'bootstrap/variables';
$registered: (ratio: (25: 4, 33: 3, 44: 2.254196643, 56: 1.777426993, 67: 1.5, 75: 1.333333333, 77: 1.298960139, 82: 1.219512195, 93: 1.071360609, 100: 1, 122: 0.82, 127: 0.789520426, 133: 0.75, 140: 0.712142857, 150: 0.666666667, 160: 0.625222025, 178: 0.562611012, 190: 0.526198934, 193: 0.518206039, 211: 0.474285714, 225: 0.44354403, 235: 0.425399645, 300: 0.333333333, 400: 0.25), max_width: (940, 700, 720, 350, 233, 235, 313, 470, 544, 485, 215, 651, 289, 684, 256, 215, 483, 140, 420, 938, 288, 650, 349));
;
@import '00-variables';
@import '01-mixins';

@import 'bootstrap/bootstrap-reboot';

/**
 * Bootstrap Grid, cleaned
 */

@import 'bootstrap/grid';

@import 'bootstrap/utilities/_screenreaders';
@import 'bootstrap/type';

@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-Thin.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-Thin.woff' ) format( 'woff' );
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-Light.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-Light.woff' ) format( 'woff' );
	font-weight: 300;
	font-style: normal;
}

@import '10-xs-atf';
@media ( min-width: map-get( $grid-breakpoints, sm ) ) {
  @import '11-sm-atf';
}
@media ( min-width: map-get( $grid-breakpoints, md ) ) {
  @import '12-md-atf';
}
