/**
 * Globals
 * Apply everywhere, from ATF, on ALL screens
 */

html {
  -webkit-text-size-adjust: none;
}
body {
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;

  letter-spacing: -.01em;

  @include themed() {
    background-color: t('body-bg');
    color: t('body-color');
  }
}
h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  margin: 2em 0 1em;
  letter-spacing: -.02em;
}
// .breadcrumb span[rel="parent"] a,
// .breadcrumb span.parent a,
// .breadcrumb>span,
h2 .h2sup,
h2 .time {
  @include themed() {
    color: t( 'color-primary' );
  }
  font-weight: 300;
  margin-bottom: .5em;
}

h2, h3, h4,
.h2, .h3 {
  margin-top: 1em;
  padding-top: 2em;
}

h2 {
  .h2sup {
    font-size: $h3-font-size;
  }
}

.entry-content {
  li+li {
    margin-top: 1em;
  }
  .col:first-child>*:first-child {
    padding-top: 0;
  }
  h2 + h3 {
    padding-top: 0;
  }
}
p {
  margin: 1em 0;
}
.nobr {
  white-space: nowrap;
}
hr.deep-breath {
  margin: 2em 0;
  border: none;
}

figure {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

//

#branding .hgroup {
  padding: $grid-gutter-width;
  text-align: left;
  line-height: 1;
}

#branding .hgroup {

  a {
    @include themed() {
      fill: t('body-color');
    }
    padding: 6px;
    display: inline-block;
    margin-top: -6px;
    margin-left: -6px;
    margin-bottom: -6px;
  }
  svg {
    height: 15px;
    display: inline-block;
    margin-top: -3px;
  }
}

#opener {

  position: fixed;
  z-index: 999;

  border: none;
  padding: 0;
  background: transparent;

  top: $grid-gutter-width;
  right: $grid-gutter-width;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  transform: translate( 10px, -12px );
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 16px;
    left: 10px;
    width: 19px;
    height: 1.4px;
    @include themed() {
      background: t( 'body-color' );
    }
  }
  &:after {
    top: auto;
    bottom: 16px;
  }

}


#quick-access {
  display: none;
}
#access {

  position: fixed;
  visibility: hidden;
  opacity: 0;
  transform: translate( -100%, 0 );

}

/**
 * Content
 */

.site-content-contain {
  padding-left: math.div( $grid-gutter-width, 2 );
  padding-right: math.div( $grid-gutter-width, 2 );
}

.entry-header {
  margin-bottom: $space-8;

  &.light {

    .entry-title {
      @include media-breakpoint-down( md ) {
        margin-top: $space-4;
      }
      margin-bottom: $space-4;
    }

  }

  .entry-title {
    margin-bottom: 2em;

    @include media-breakpoint-down( xs ) {
      hyphens: auto;
    }
  }

}

.header-meta {
  font-family: $headings-font-family;
  font-size: $font-size-sm;
  margin-top: math.div( $grid-gutter-width, 2 );
  color: $color-secondary;
  text-align: right;

  .entry-header.light & {
    text-align: left;
  }
}
.separator {
  user-select: none;
  margin: 0 .2em;
  opacity: .4;
}
.reading-time:after {
  content: attr( title );
}

.breadcrumb {

  header.light & {
    margin-top: $space-4;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li:not(:nth-last-child(2)) {
    @extend .sr-only;
  }

  &[data-breadcrumb="1"]+h1 {
    margin-top: 0 !important;
  }

  li>* {
    @extend h3;
    display: block;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: .5em;
  }
}

.pfc {
  @include media-breakpoint-down( sm ) {
    padding: 0;
    margin-left: math.div( -$grid-gutter-width, 2 );
    margin-right: math.div( -$grid-gutter-width, 2 );
    width: calc( 100% + #{$grid-gutter-width} );
    flex-basis: calc( 100% + #{$grid-gutter-width} );
    max-width: calc( 100% + #{$grid-gutter-width} );
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.rp {
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
  }
}

@each $ratio, $padding in map-get( $registered, ratio ) {
  .rp-#{$ratio} {
    padding-bottom: $padding * 100%;
  }
}

ul.spaced {
  font-size: $font-size-md;
  padding-left: 1.4em;
}

//
// Photography
//

body.photography-gallery {

  .entry-content {

    .photo {
      min-height: 80vh;
      align-items: center;
    }

  }

}

//

.carousel {
  margin: 0;

  &,
  .flickity-viewport {
    height: 80vh;
    // max-height: 480px;
  }

  .mc {
    margin: 0;
    width: 100%;
    height: 100%;

    .photo,
    .photo .pictbox,
    .photo .pictbox .img,
    .photo .pictbox .img img {
      height: 100%;
      object-fit: cover;
    }
  }
  .rp {
    padding: 0;
  }

  .img img {
    filter: initial;
  }

}
.go-down {
  width: 100%;
  text-align: center;
  margin-top: $space-4;

  button {
    background: transparent;
    border: none;
  }
  svg {
    width: 1em;
    height: 1em;

    @include dark() {
      fill: #fff;
    }
  }
}
